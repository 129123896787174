<template>
    <div>
        <div class="mb-2 d-flex">
            <b-button variant="light"
                      class="border w-40 h-40 mr-2"
                      :class="show ? null : 'collapsed'"
                      :aria-expanded="show ? 'true' : 'false'"
                      aria-controls="collapse-4"
                      @click="show = !show"
            >
                <i class="ri-arrow-down-s-line" v-if="!show"></i>
                <i class="ri-arrow-up-s-line" v-if="show"></i>
            </b-button>
            <div class="flex-grow-1 bg-light border rounded-sm d-flex align-items-center pl-3 font-weight-bold">
                <slot name="title"/>
            </div>
        </div>
        <b-collapse id="collapse-4" v-model="show">
            <div class="border rounded-sm p-4 mb-4">
                <slot name="detail"/>
            </div>
        </b-collapse>
    </div>
</template>

<script>
    export default {
        props: {
            showStatus: {
                type: Boolean,
                default: false
            }
        },
        created() {
            this.show = this.showStatus
        },
        data() {
            return {
                show: false
            }
        }
    }
</script>
