<template>
    <div>
        <b-form-select
            v-model="selected"
            :options="options"
            :class="validateError ? 'box-border-color' : ''"
            @input="handleInput"
        />
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError">
    </span>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                default: null
            },
            validateError: {
                default: null
            }
        },
        watch: {
            value: function(newValue) {
                this.selected = newValue;
            }
        },
        data() {
            return {
                selected: null,
                options: [
                    {value: null, text: this.$t('select')},
                    {value: 0, text: 0},
                    {value: 1, text: 1},
                    {value: 2, text: 2},
                    {value: 3, text: 3},
                    {value: 4, text: 4},
                    {value: 5, text: 5},
                    {value: 6, text: 6},
                    {value: 7, text: 7},
                    {value: 8, text: 8},
                    {value: 9, text: 9},
                    {value: 10, text: 10},
                    {value: 11, text: 11},
                    {value: 12, text: 12}
                ]
            }
        },
        created() {
            this.selected = this.value
        },
        methods: {
            handleInput(event) {
                this.$emit('input', event);
            }
        }
    }
</script>
